
import { Timeline } from 'primereact/timeline';
import { PrimeIcons } from 'primereact/api';

const helpContent = [
    {
      status: "Click",
      content:
        "Click on a map location (point) to view the site's details in a popup.",
      icon: PrimeIcons.EYE,
    },
    {
      status: "Data Table",
      content:
        "Use the Data Table button control on the right to view all PVACD locations at once with their most recent data.",
      icon: PrimeIcons.TABLE,
    },
    {
      status: "Hydrograph",
      content: (
        <div>
          Click the chart icon
          <i className="pi pi-chart-line" style={{ marginLeft: "4px", marginRight: "4px" }} />
          next to any site in the data table to view the site's hydrograph. The hydrograph will show trends in water levels over time for manual and continuous measurements.
        </div>
      ),
      icon: PrimeIcons.CHART_LINE,
    },
    {
      status: "Download",
      content:
        "Use the Download Data button at the bottom of the table to download data for selected locations. If no locations are selected, the button is disabled.",
      icon: PrimeIcons.CLOUD_DOWNLOAD,
    },
    {
      status: "Map Controls",
      content: (
        <>
          Use the map controls in the upper-right corner to change the basemap style (light, dark, satellite, etc.), zoom in/out, and reset the map view.
        </>
      ),
      icon: PrimeIcons.MAP,
    },
  ];

const PVACDHelp = () => {
    return (
            <Timeline 
                className="m-3" 
                value={helpContent} 
                align="left" 
                content={(helpContent) => (
                    <div>
                        <b>{helpContent.status}</b>
                        <p>{helpContent.content}</p>
                    </div>
                )}
                marker={(helpContent) => (
                    <div className="icon-circle">
                        <i className={`pi ${helpContent.icon}`}></i>
                    </div>
                )}
            />
    );
};

export default PVACDHelp;