// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import Papa from "papaparse";
import { stringify, parse } from "wkt";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import MapComponent from "../Map/MapComponent";
import { downloadCSVfromText, downloadZip } from "../download_util";
import { DataTable } from "primereact/datatable";
import counties from "../../data/nmcounties.json";
import { Column } from "primereact/column";
import { Layer, Source } from "react-map-gl";
import BBoxControl from "./BBoxControl";
import SourceControl from "./SourceControl";
import InputControl from "./InputControl";
import { ProgressBar } from "primereact/progressbar";
import { settings } from "../../settings";
import { mToFt } from "../../util";
import { Toast } from "primereact/toast";
import { useSessionToast } from "../../hooks/useSessionToast";
import {
  all_sources,
  analyte_sources,
  dtw_sources,
  nmbgmr,
  usgs,
} from "./sources";
import * as turf from "@turf/turf";

const DEFAULT_WKT =
  "POLYGON ((-108.25996530415048 36.78079831174655, -108.29486198642269 36.684183408351956, " +
  "-108.14893040601262 36.63328464263222, -107.83168783990364 36.84429441876627, " +
  "-107.81265328593707 37.08256805531879, -107.80630843461526 37.25447092518054," +
  "-107.85389481953165 37.29990914393716, -107.9268606097364 37.31757215403016, " +
  "-107.8887915018033 37.24689522233464, -107.85706724519255 37.173624167875175, " +
  "-107.95858486634712 36.912811040128716, -108.10134402109627 36.86206388946127, " +
  "-108.25996530415048 36.78079831174655))";

const outputstyles = [
  { name: "Summary", code: "summary" },
  { name: "Time Series", code: "timeseries" },
];

// const settings.die_worker_url = "http://localhost:8080/";
// const DIE_WORKER_URL = "http://127.0.0.1:5000/";
// const settings.die_worker_url = "https://die-dot-waterdatainitiative-271000.appspot.com/";
// const settings.die_worker_url =
//   "https://die-worker-dot-waterdatainitiative-271000.appspot.com/";
// const DIE_WORKER_URL =
//   "https://die-worker-dot-waterdatainitiative-271000.appspot.com/";

export default function DataIntegrationEngine() {
  const sessionToast = useRef(null);
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const [selectedSources, setSelectedSources] = useState([usgs]);
  const [displaySources, setDisplaySources] = useState([]);
  const [enabledSources, setEnabledSources] = useState([
    usgs.code,
    nmbgmr.code,
  ]);
  const [selectedCounty, setSelectedCounty] = useState({
    // name: "Eddy",
    // code: "",
  });
  const [selectionPolygons, setSelectionPolygons] = useState([]); // [polygon1, polygon2, ...
  const [selectedParameter, setSelectedParameter] = useState({
    name: "Depth To Groundwater",
    code: "dtw",
  });
  const [selectedOutputStyle, setSelectedOutputStyle] = useState({
    name: "Summary",
    code: "summary",
  }); // 'summary' or 'timeseries'
  const [sources, setSources] = useState(all_sources); // [{name: "USGS", code: "nwis"}, ...
  const [resultsData, setResultsData] = useState();
  const [boundaryData, setBoundaryData] = useState();

  const mapRef = useRef();
  const mapStyle = "mapbox://styles/mapbox/satellite-streets-v11";
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [bbox, setBbox] = useState({
    minLat: 0,
    maxLat: 0,
    minLng: 0,
    maxLng: 0,
  });

  const [popupContent, setPopupContent] = useState();
  const [stickyPopup, setStickyPopup] = useState(false);

  const getStatus = (task_name, downloadhash) => {
    return () => {
      console.log("asdf", task_name);

      fetch(
        settings.die_url +
          "status?" +
          new URLSearchParams({ task_id: task_name }),
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("status", data, task_name);
          if (data["status"] === "finished") {
            fetch_result(downloadhash);
          } else {
            setTimeout(getStatus(task_name, downloadhash), 3000);
          }
        });
    };
  };
  const fetch_result = (downloadhash) => {
    let output_summary = false;
    if (selectedOutputStyle["code"] === "summary") {
      output_summary = true;
    }

    fetch(
      settings.die_url +
        "download_unified_waterlevels?" +
        new URLSearchParams({
          downloadhash: downloadhash,
          output_summary: output_summary,
        }),
    )
      .then((response) => {
        if (output_summary === true) {
          return response.text();
        } else {
          return response.blob();
        }
      })
      .then((data) => {
        if (output_summary === true) {
          downloadCSVfromText("unified_water_levels.csv", data);
          let pd = Papa.parse(data, { header: true });
          setResults(pd.data);
          setResultsData({
            id: "results",
            type: "FeatureCollection",
            features: pd.data.map((d) => {
              return {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [d["longitude"], d["latitude"]],
                },
                properties: d,
              };
            }),
          });
        } else {
          downloadZip("unified_water_levels.zip", data);
        }

        setLoading(false);
      });
  };
  const handleSubmit = () => {
    console.log("handling submit");

    let ss = selectedSources.filter((source) =>
      enabledSources.includes(source["code"]),
    );
    if (ss.length === 0) {
      alert("Please select at least one source");
    }

    let body = { sources: ss.map((source) => source["code"]) };
    body["output_summary"] = selectedOutputStyle["code"] === "summary";

    if (selectedCounty) {
      body["county"] = selectedCounty["name"];
    }

    let polygonKeys = Object.keys(selectionPolygons);
    if (polygonKeys.length > 0) {
      let polygon = selectionPolygons[polygonKeys[0]];
      body["wkt"] = stringify(polygon);
    }

    // if (DEFAULT_WKT) {
    //   body["wkt"] = DEFAULT_WKT;
    // }

    if (startDate) {
      body["start_date"] = startDate.toISOString();
    }
    if (endDate) {
      body["end_date"] = endDate.toISOString();
    }
    console.log("posting to DIE Worker", body);

    setLoading(true);
    fetch(settings.die_url + "trigger_unify_waterlevels", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("asdf", data);
        if (data["task_response"] !== null) {
          setTimeout(
            getStatus(data["task_response"]["name"], data["downloadhash"]),
            1000,
          );
        } else {
          fetch_result(data["downloadhash"]);
        }
      });
  };

  // const defaultFeatures = [
  //   {
  //     type: "feature",
  //     geometry: parse(DEFAULT_WKT),
  //     properties: { name: "default" },
  //   },
  // ];

  // UseEffects
  useEffect(() => {
    console.log("selectedCounty", selectedCounty, selectedCounty.code);
    if (selectedCounty.code === undefined) {
      setBboxEnabled(true);
    } else {
      setBboxEnabled(false);
    }
    if (mapRef.current !== null) {
      let m = mapRef.current.getMap();

      // hide all the county layers
      for (const county of counties) {
        let layer = m.getLayer(county.code + "fill");
        if (layer !== undefined) {
          m.setLayoutProperty(layer.id, "visibility", "none");
          m.setLayoutProperty(county.code + "line", "visibility", "none");
          m.setLayoutProperty(county.code + "label", "visibility", "none");
        }
      }

      if (selectedCounty.code === undefined) {
        mapRef.current.flyTo({
          center: [-106.0, 35.0],
          zoom: 5,
        });
        return;
      }

      const flyTo = (county_code) => {
        let s = m.getSource(county_code);
        let center = turf.centroid(s.serialize()["data"]);
        mapRef.current.flyTo({
          center: center.geometry.coordinates,
          zoom: 7,
        });
      };

      if (m.getSource(selectedCounty.code) !== undefined) {
        ["fill", "line", "label"].forEach((layer) => {
          m.setLayoutProperty(
            selectedCounty.code + layer,
            "visibility",
            "visible",
          );
        });

        flyTo(selectedCounty.code);
      } else {
        fetch(
          settings.die_worker_url +
            "county_bounds?county=" +
            selectedCounty.code,
        )
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            m.addSource(selectedCounty.code, {
              type: "geojson",
              data: parse(data["wkt"]),
            });
            m.addLayer({
              id: selectedCounty.code + "fill",
              type: "fill",
              source: selectedCounty.code,
              layout: {},
              paint: {
                "fill-color": "#46a1a1",
                "fill-opacity": 0.5,
              },
            });
            m.addLayer({
              id: selectedCounty.code + "line",
              type: "line",
              source: selectedCounty.code,
              layout: {},
              paint: {
                "line-color": "#b42722",
              },
            });
            m.addLayer({
              id: selectedCounty.code + "label",
              type: "symbol",
              source: selectedCounty.code,
              layout: {
                "text-field": selectedCounty.name,
                "text-font": ["Open Sans Regular"],
                "text-size": 12,
              },
            });
            flyTo(selectedCounty.code);
          });
      }
    }
  }, [selectedCounty]);

  useEffect(() => {
    let codes = displaySources.map((source) => {
      return source.code;
    });
    if (codes.length === 0) {
      setBoundaryData({ type: "FeatureCollection", features: [] });
    } else {
      // get the boundary from the source

      // join the codes with a comma
      codes = codes.join(",");

      fetch(settings.die_worker_url + `source_bounds?sources=${codes}`)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data["wkt"] === null) {
            return;
          }
          setBoundaryData(parse(data["wkt"]));
        });
    }
  }, [displaySources]);

  useEffect(() => {
    if (selectedParameter === undefined) {
      return;
    }

    if (selectedParameter.code === "dtw") {
      setSources(dtw_sources);
    } else {
      setSources(analyte_sources);
    }
  }, [selectedParameter]);

  useEffect(() => {
    let ss = Object.keys(selectionPolygons).map((key) => {
      let polygon = selectionPolygons[key];
      if (polygon === undefined) {
        return undefined;
      }

      if (polygon.geometry.type === "Polygon") {
        let url =
          settings.die_worker_url +
          "sources?" +
          "&wkt=" +
          stringify(selectionPolygons[key]["geometry"]);
        if (selectedParameter.code !== "dtw") {
          url += "&parameter=" + selectedParameter.code;
        }
        return fetch(url)
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            console.log("cotained sources", data);
            return data["sources"];
          });
      }
      return undefined;
    });

    Promise.all(ss).then((data) => {
      // let sources = data.flat();
      setEnabledSources(data.flat());
    });
    // console.log("asfffffdf", ss);
    // Promise.resolve(ss).then((data) => console.log(data));

    for (const key of Object.keys(selectionPolygons)) {
      fetch(
        settings.die_worker_url +
          "sources?wkt=" +
          stringify(selectionPolygons[key]["geometry"]),
      )
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          console.log("cotained sources", data);
          setEnabledSources((prev) => [
            ...new Set([...prev, ...data["sources"]]),
          ]);
        });
    }
  }, [selectionPolygons]);

  // map event handlers
  const onMouseMove = (e, features, selected_points) => {
    let selected_feature = features[0];
    let selected_point = selected_points[0];
    // console.log("selected_point", selected_point, sp);
    if (selected_point === undefined) {
      return;
    }
    let properties = selected_feature.properties;
    let rows = [
      { key: "ID", value: properties.id },
      {
        key: "Latitude",
        value: properties.latitude,
      },
      {
        key: "Longitude",
        value: properties.longitude,
      },
      {
        key: "Elevation (ft asl)",
        value: properties.elevation,
      },
      {
        key: "Well Depth (ft bgs)",
        value: properties.well_depth,
      },
      {
        key: "USGS Site ID",
        value: properties.usgs_site_id,
      },
    ];

    setPopupContent({
      coordinates: [properties.longitude, properties.latitude],
      properties: properties,
      children: (
        <>
          <DataTable
            value={rows}
            size={"small"}
            stripedRows
            showGridlines
            className={"popup-table"}
            showHeaders={false}
          >
            <Column field={"key"} h></Column>
            <Column field={"value"}></Column>
          </DataTable>
        </>
      ),
    });
  };

  let resultsView;
  if (selectedOutputStyle.code === "summary") {
    if (resultsData === undefined || resultsData.features.length === 0) {
      resultsView =
        "Results will be displayed on the map, in the table below, and will be downloaded as a CSV file.";
    } else {
      resultsView = (
        <DataTable
          value={results}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          size={"small"}
          className={"compact-table"}
        >
          <Column field="source" header="Source" />
          <Column field="id" header="Id" />
          <Column field="location" header="Location" />
          <Column field="usgs_site_id" header="USGS Site Id" />
          <Column field="alternate_site_id" header="Alternate Site Id" />
          <Column field="latitude" header="Latitude" />
          <Column field="longitude" header="Longitude" />
          <Column field="elevation" header="Elevation" />
          <Column field="elevation_units" header="Elevation Units" />
          <Column field="well_depth" header="Well Depth" />
          <Column field="well_depth_units" header="Well Depth Units" />
          <Column field="parameter" header="Parameter" />
          <Column field="parameter_units" header="Parameter Units" />
          <Column field="nrecords" header="N. Records" />
          <Column field="min" header="Min" />
          <Column field="max" header="Max" />
          <Column field="mean" header="Mean" />
          <Column field="most_recent_date" header="Most Recent Date" />
          <Column field="most_recent_time" header="Most Recent Time" />
          <Column field="most_recent_value" header="Most Recent Value" />
          <Column field="most_recent_units" header="Most Recent Units" />
        </DataTable>
      );
    }
  } else {
    resultsView =
      "Your results will be downloaded as a zip zip file containing a CSV file with the unified data";
  }

  useSessionToast(sessionToast, "DIE-toast", {
    severity: "warn",
    summary: "Page Under Construction",
    detail: "We’re working hard to create a fantastic new experience for you. Check back soon for updates!",
    life: 6000
  });

  return (
    <div className={"grid justify-content-center"}>
      <Toast ref={sessionToast} position="top-center"/>
      <div className={"grid m-3 col-12 md:col-10 justify-content-center"}>
        {/* Hide website under construction and replace with session-based toast notification for now */}
      {/* <Card className={"col-12 m-3"}>
        <div className={"col-12 surface-300"}>
          <div className={"text-center"}>
            <h2>🚧 Website Under Construction 🚧 </h2>
            <h3>We’re Building Something Amazing!</h3>
          </div>
          <div className={"flex justify-content-center"}>
            <p className={"w-12 md:w-8"}>
              Thank you for visiting the NM Data Integration Engine. We’re
              currently working hard to create a fantastic new experience for
              you. Our team is dedicated to bringing you the best, and we can’t
              wait to show you what’s in store. Check back soon for updates!
            </p>
          </div>
        </div>
      </Card> */}
      <Card className={"col-12 mb-3"}>
      <h1
            style={{
              textAlign: "center",
            }}
          >
            Data Integration Engine
          </h1>
        <div className={"flex"}>
        <p className={""}>
          The Data Integration Engine is a tool that allows users to spatially
          filter and integrate data from multiple sources. The tool allows users
          to select a county or a bounding polygon to spatially filter data. The
          tool then integrates data from multiple sources and provides a unified
          dataset.
        </p>
        </div>
      </Card>
      </div>
      <div className={"grid md:col-10 mb-3"}>
        <Card title={"Inputs"} className={"col-12 md:col-3"}>
          <InputControl
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedParameter={selectedParameter}
            setSelectedParameter={setSelectedParameter}
          />
        </Card>
        <Card title={"Spatial Filter"} className={"col-12 md:col-6"}>
          <div className={"grid"}>
            <div className={"col-12"}>
              <label className={"p-3"}>County:</label>
              <Dropdown
                value={selectedCounty}
                options={counties}
                onChange={(e) => setSelectedCounty(e.value)}
                optionLabel="name"
                placeholder="Select a County"
              />
              <Button
                onClick={() => setSelectedCounty({})}
                tooltip={"Clear County Selection"}
                className={"mr-2 ml-2"}
                icon={"pi pi-undo"}
              ></Button>
            </div>
            <div className={"col-fixed"} style={{ width: "400px" }}></div>
          </div>

          <div>
            <BBoxControl bbox={bbox} setBbox={setBbox} enabled={bboxEnabled} />
          </div>
          <hr />
          <div>
            <MapComponent
              mapStyle={mapStyle}
              selectionPolygons={selectionPolygons}
              setSelectionPolygons={setSelectionPolygons}
              // defaultFeatures={defaultFeatures}
              initialViewState={{
                longitude: -106.0, //-108.0,
                latitude: 35, //37.0,
                zoom: 5, //8,
              }}
              sourceData={{ results: resultsData, boundaries: boundaryData }}
              sources={[
                {
                  id: "boundaries",
                  label: "Boundaries",
                  url: "foo",
                  layers: [
                    {
                      id: "boundariesfill",
                      type: "fill",
                      paint: {
                        "fill-color": "#224bb4",
                        "fill-opacity": 0.3,
                      },
                    },
                    {
                      id: "boundariesline",
                      type: "line",
                      paint: { "line-color": "#b42722", "line-width": 2 },
                    },
                  ],
                },
                {
                  id: "results",
                  label: "Results",
                  url: "foob",
                  paint: {
                    "circle-radius": 4,
                    "circle-stroke-width": 1,
                    "circle-stroke-color": "#000000",
                    "circle-color": [
                      "match",
                      ["get", "source"],
                      "NMBGMR",
                      "#d5633a",
                      "USGS-NWIS",
                      "#3a67d5",
                      "#e5c6c6",
                    ],
                  },
                },
              ]}
              onMouseMoveCallback={onMouseMove}
              // onMouseClickCallback={onMouseClick}
              // onContextMenuCallback={onContextMenu}
              popupContent={popupContent}
              setPopupContent={setPopupContent}
              stickyPopup={stickyPopup}
              setStickyPopup={setStickyPopup}
              // sources={sources}
              // layerVisibility={layerVisibility}
              style={{ width: "100%", height: "500px" }}
              showDrawControls={{ show: true, position: "top-right" }}
              mapRef={mapRef}
            />
          </div>
        </Card>

        <Card title={"Sources"} className={"col-12 md:col-3"}>
          <SourceControl
            sources={sources}
            enabledSources={enabledSources}
            selectedSources={selectedSources}
            setSelectedSources={setSelectedSources}
            displaySources={displaySources}
            setDisplaySources={setDisplaySources}
          />
        </Card>
      </div>
      <div className={"grid col-12 md:col-10 justify-content-center"}>
      <Card title={"Outputs"} className={"col-12 mb-3"}>
        <div className={"grid"}>
          <div className={"col-12 md:col-3"}>
            <label className={"p-3"}>Output Style:</label>
            <Dropdown
              className={"mt-3 mb-3"}
              value={selectedOutputStyle}
              options={outputstyles}
              onChange={(e) => setSelectedOutputStyle(e.value)}
              optionLabel="name"
              placeholder="Select an output style"
            />
          </div>
          <div className={"col-12"}>
            {selectedOutputStyle.code === "timeseries" &&
              "One file per site + one site metadata file"}
            {selectedOutputStyle.code === "summary" &&
              "One row per site.  Each row contains site metadata, and time series stats (number of measurements," +
                " min, max, latest, etc)"}
          </div>
        </div>
      </Card>
      <Card title={"Results"} className={"col-12 mb-3"}>
        <div className={"grid"}>
          <div className={"col-12"}>
            <div className={"grid"}>
              <div className={"col-12"}>
                <Button label={"Submit"} onClick={handleSubmit} />
              </div>
              <div className={"col-12"}>
                {loading && (
                  <div>
                    <h3 className={"text-center"}>
                      Loading results. This may take a few minutes. Thank you
                      for your patience
                    </h3>
                    <ProgressBar
                      className={"mt-6"}
                      mode="indeterminate"
                      style={{ height: "6px" }}
                    ></ProgressBar>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={"col-12"}>{resultsView}</div>
        </div>
      </Card>
      </div>
      </div>
  );

  // return (
  //
  //   <div>
  // <Card>
  // <Stepper
  //         orientation={"vertical"}
  //         ref={stepperRef}
  //         style={{ flexBasis: "50rem" }}
  //       >
  //         <StepperPanel header=" Spatial Filter ">
  //           <div className="flex flex-column">
  //             {/*<div className="border-2 border-dashed surface-border border-round*/}
  //             {/*surface-ground flex-auto flex justify-content-center font-medium">*/}
  //             <div
  //               className={
  //                 "border-2 border-dashed surface-border border-round surface-ground"
  //               }
  //             >
  //               <div>
  //                 <label className={"p-3"}>County</label>
  //                 <Dropdown
  //                   value={selectedCounty}
  //                   options={counties}
  //                   onChange={(e) => setSelectedCounty(e.value)}
  //                   optionLabel="name"
  //                   placeholder="Select a County"
  //                 />
  //               </div>
  //               <div>
  //                 <div className={"p-fluid grid formgrid"}>
  //                   <label className={"p-3"}>Bounding Box</label>
  //                   <div className="field col-12 md:col-2">
  //                     <label htmlFor="minLat">Min. Lat</label>
  //                     <InputNumber
  //                       inputId="minLat"
  //                       value={bbox.minLat}
  //                       onValueChange={(e) => setBbox({ minLat: e.value })}
  //                     />
  //                   </div>
  //                   <div className="field col-12 md:col-2">
  //                     <label htmlFor="maxLat">Max. Lat</label>
  //                     <InputNumber
  //                       inputId="maxLat"
  //                       value={bbox.maxLat}
  //                       onValueChange={(e) => setBbox({ maxLat: e.value })}
  //                     />
  //                   </div>
  //                   <div className="field col-12 md:col-2">
  //                     <label htmlFor="minLng">Min. Lng</label>
  //                     <InputNumber
  //                       inputId="minLng"
  //                       value={bbox.minLng}
  //                       onValueChange={(e) => setBbox({ minLng: e.value })}
  //                     />
  //                   </div>
  //                   <div className="field col-12 md:col-2">
  //                     <label htmlFor="maxLng">Min. Lng</label>
  //                     <InputNumber
  //                       inputId="maxLng"
  //                       value={bbox.maxLng}
  //                       onValueChange={(e) => setBbox({ maxLng: e.value })}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //               <hr />
  //               <div>
  //                 <MapComponent
  //                   mapStyle={mapStyle}
  //                   selectionPolygons={selectionPolygons}
  //                   setSelectionPolygons={handlePolygonSelect}
  //                   // setSourceData={(data) => {
  //                   //     setSourceData(data);
  //                   //     setUnfilteredSourceData(data);
  //                   // }}
  //                   // sourceData={sourceData}
  //                   // onMouseMoveCallback={onMouseMove}
  //                   // onMouseClickCallback={onMouseClick}
  //                   // onContextMenuCallback={onContextMenu}
  //                   // popupContent={popupContent}
  //                   // setPopupContent={setPopupContent}
  //                   // stickyPopup={stickyPopup}
  //                   // setStickyPopup={setStickyPopup}
  //                   // sources={sources}
  //                   // layerVisibility={layerVisibility}
  //                   style={{ width: "75%", height: "500px" }}
  //                   showDrawControls={{ show: true, position: "top-right" }}
  //                   mapRef={mapRef}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="flex pt-4 justify-content-end">
  //             <Button
  //               label="Next"
  //               icon="pi pi-arrow-right"
  //               iconPos="right"
  //               onClick={() => stepperRef.current.nextCallback()}
  //             />
  //           </div>
  //         </StepperPanel>
  //         <StepperPanel header="Header II">
  //           <div className="flex flex-column h-12rem">
  //             <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
  //               Content II
  //             </div>
  //           </div>
  //           <div className="flex pt-4 justify-content-between">
  //             <Button
  //               label="Back"
  //               severity="secondary"
  //               icon="pi pi-arrow-left"
  //               onClick={() => stepperRef.current.prevCallback()}
  //             />
  //             <Button
  //               label="Next"
  //               icon="pi pi-arrow-right"
  //               iconPos="right"
  //               onClick={() => stepperRef.current.nextCallback()}
  //             />
  //           </div>
  //         </StepperPanel>
  //         <StepperPanel header="Header III">
  //           <div className="flex flex-column h-12rem">
  //             <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
  //               <Button label={"Submit"} onClick={handleSubmit} />
  //             </div>
  //           </div>
  //           <div className="flex pt-4 justify-content-start">
  //             <Button
  //               label="Back"
  //               severity="secondary"
  //               icon="pi pi-arrow-left"
  //               onClick={() => stepperRef.current.prevCallback()}
  //             />
  //           </div>
  //         </StepperPanel>
  //       </Stepper>
  //     </Card>
  //   </div>
  // );
}
// ============= EOF =============================================
