// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import "./App.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css"; //flex
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { FiefAuthProvider } from "@fief/fief/react";
import { Callback, RequireAuth } from "./fief";
import { PrimeReactProvider } from "primereact/api";
import React, { lazy, useEffect, useState } from "react";
import { settings } from "./settings";
import ChemForm from "./components/Entry/ChemUpload";
import ManualWaterLevelForm from "./components/Entry/ManualWaterLevel";
import GroundwaterDashboard from "./components/Dashboards/Groundwater/Groundwater";
import SurfacewaterDashboard from "./components/Dashboards/Surfacewater";
import Location from "./components/Location/Location";
import DataView from "./components/DataView/DataView";
import WellUpload from "./components/Entry/WellUpload";
import SiteCrossRef from "./components/SiteCrossRef/SiteCrossRef";
import Home from "./components/Home/Home";
import Footer from "./components/Footer";
import AppNavbar from "./components/NavBar/NavBar";
// import Documentation from "./components/Documentation/Documentation";
import MainDocumentationPage from "./components/Documentation/Main";
import PWS from "./components/PWS";
import DataIntegrationEngine from "./components/DataIntegration/DataIntegrationEngine";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HydrographViewer from "./components/DataView/Hydrograph";
import STLocations from "./components/ST/STLocations";
import STManager from "./components/ST/STManager";
import ContinuousDashboard from "./components/Continuous/Dashboard";
import ReactGA from "react-ga4";
import STLocationEditor from "./components/ST/STLocationEditor";
import STOnboarder from "./components/ST/STOnboarder";
import BatchManualWaterLevelsUpload from "./components/Entry/BatchManualWaterLevelsUpload";
import PVACDDashboard from "./components/Dashboards/PVACD/PVACD";

// const Dashboard = lazy(
//   () => import("./components/Dashboards/Discovery/Discovery"),
// );
import * as Sentry from "@sentry/react";

console.log("process.env.SENTRY_DSN", process.env.REACT_APP_SENTRY_DSN);
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://weaver.newmexicowaterdata.org",
    "https://weaverb.newmexicowaterdata.org",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Wells = lazy(() => import("./components/Wells/Wells"));
const CollabNet = lazy(() => import("./components/CollabNet/CollabNet"));

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

const queryClient = new QueryClient();

// only initialize if in production
// if (process.env.NODE_ENV === "production") {
//   ReactGA.initialize("G-3SSZ4P5HF3");
// }

function App() {
  // const [helpVisible, setHelpVisible] = useState(false);
  // const location = useLocation();
  // useEffect(() => {
  //   console.log("send pageview", location.pathname + location.search);
  //
  //   ReactGA.send({
  //     hitType: "pageview",
  //     page: location.pathname + location.search,
  //     title: location.pathname,
  //   });
  //   // ReactGA.send(location.pathname + location.search);
  // }, [location]);


  //Add document title based on the current page
  const location = useLocation();
  const pointIDLocation = location.pathname.match(/^\/location\/(.+)$/);
  useEffect(() => {
    if (pointIDLocation) {
      const pointID = pointIDLocation[1];
      document.title = `Weaver | Location | ${pointID}`;
    }
    else {
      //console.log("location.pathname", location.pathname);
    switch (location.pathname) {
      case "/":
        document.title = "Weaver";
        break;
      case "/groundwater":
        document.title = "Weaver | Groundwater";
        break;
      case "/surfacewater":
        document.title = "Weaver | Surfacewater";
        break;
      case "/pvacd":
        document.title = "Weaver | PVACD";
        break;
      case "/dashboard":
        document.title = "Weaver | Dashboard";
        break;
      case "/wells":
        document.title = "Weaver | Wells";
        break;
      case "/well_upload":
        document.title = "Weaver | Well Upload";
        break;
      case "/chem_upload":
        document.title = "Weaver | Chemistry Upload";
        break;
      case "/manual_water_level_entry":
        document.title = "Weaver | Manual Water Level Entry";
        break;
      case "/batch_manual_waterlevels_upload":
        document.title = "Weaver | Batch Manual Water Level Upload";
        break;
      case "/site_cross_ref":
        document.title = "Weaver | Site Cross Reference";
        break;
      case "/docs":
        document.title = "Weaver | Documentation";
        break;
      case "/collabnet":
        document.title = "Weaver | Healy Collaborative Network";
        break;
      case "/pws":
        document.title = "Weaver | PWS";
        break;
      case "/dataview":
        document.title = "Weaver | Data View";
        break;
      case "/hv":
        document.title = "Weaver | Hydrograph Viewer";
        break;
      case "/die":
        document.title = "Weaver | Data Integration Engine";
        break;
      case "/st":
        document.title = "Weaver | Sensor Things";
        break;
      case "/sto":
        document.title = "Weaver | Sensor Things Onboarder";
        break;
      case "/ste":
        document.title = "Weaver | Sensor Things Editor";
        break;
      case "/continuous":
        document.title = "Weaver | Continuous Dashboard";
        break;
      default:
        document.title = "Weaver";
    }
  }
  }, [location]);

  return (
    <PrimeReactProvider>
      <FiefAuthProvider
        baseURL={settings.fief.url}
        clientId={settings.fief.client_id}
      >
        <QueryClientProvider client={queryClient}>
          <AppNavbar />
          {/*display the floating action button */}
          {/* this shiuld be renamed to something more descriptive/accurate e.g. SubmitIssueButton*/}
          {/*<FloatingActionButton/>*/}
          {/*<BrowserRouter>*/}
          <Routes>
            <Route page path="/" element={<Home />} />
            {/*<Route*/}
            {/*  path="/dashboard"*/}
            {/*  element={*/}
            {/*    <Dashboard*/}
            {/*      helpVisible={helpVisible}*/}
            {/*      setHelpVisible={setHelpVisible}*/}
            {/*    />*/}
            {/*  }*/}
            {/*/>*/}

            {/* <Route
              path="/continuous"
              element={
                <RequireAuth>
                  <ContinuousDashboard />
                </RequireAuth>
              }
            /> */}
            <Route
              path="/ste"
              element={
                <RequireAuth>
                  <STLocationEditor />
                </RequireAuth>
              }
            />
            <Route
              path="/sto"
              element={
                <RequireAuth>
                  <STOnboarder />
                </RequireAuth>
              }
            />
            <Route path="/st" element={<STManager />} />
            <Route path="/hv" element={<HydrographViewer />} />
            <Route path="/die" element={<DataIntegrationEngine />} />
            <Route path="/groundwater" element={<GroundwaterDashboard />} />
            <Route path="/surfacewater" element={<SurfacewaterDashboard />} />
            <Route path="/pvacd" element={<PVACDDashboard />} />

            <Route path="/callback" element={<Callback />} />
            <Route path="/docs" element={<MainDocumentationPage />} />
            <Route path="/collabnet" element={<CollabNet />} />
            <Route path="/pws" element={<PWS />} />
            <Route path="/location/:paramPointId" element={<Location />} />
            <Route
              path={"/dataview"}
              element={
                <RequireAuth>
                  <DataView />
                </RequireAuth>
              }
            />
            <Route
              path="/wells"
              element={
                <RequireAuth>
                  <Wells />
                </RequireAuth>
              }
            />
            <Route
              path="/well_upload"
              element={
                <RequireAuth>
                  <WellUpload />
                </RequireAuth>
              }
            />
            <Route
              path="/chem_upload"
              element={
                <RequireAuth>
                  <ChemForm />
                </RequireAuth>
              }
            />
            <Route
              path="manual_water_level_entry"
              element={
                <RequireAuth>
                  <ManualWaterLevelForm />
                </RequireAuth>
              }
            />
            <Route
              path={"batch_manual_waterlevels_upload"}
              element={
                <RequireAuth>
                  <BatchManualWaterLevelsUpload />
                </RequireAuth>
              }
            />
            <Route path={"/site_cross_ref"} element={<SiteCrossRef />} />
          </Routes>
          {/*</BrowserRouter>*/}
          <Footer />
        </QueryClientProvider>
      </FiefAuthProvider>
    </PrimeReactProvider>
  );
}

export default App;
// ============= EOF =============================================
