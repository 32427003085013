import { Button } from 'primereact/button';

const MapTitleControl = ({ onClick }) => {
  return (
    <div className="map-title-control">
      <Button
        size='large'
        severity='secondary'
        raised
        plain
        label='PVACD Dashboard'
        icon="pi pi-info-circle"
        onClick={onClick}
        tooltip="Click for Dashboard Information"
        tooltipOptions={{ position: 'left' }}
      />
    </div>
  );
};

export default MapTitleControl;