import { Button } from 'primereact/button';

const DataTableControl = ({ onClick }) => {
  return (
    <div className="data-table-control">
      <Button
        label='Data Table'
        icon="pi pi-table"
        onClick={onClick}
        className="button p-button-raised p-button-rounded p-button-info"
        tooltip="Show Data Table"
        tooltipOptions={{ position: 'left' }}
      />
    </div>
  );
};

export default DataTableControl;