// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import {Card} from "primereact/card";
import {Button} from "primereact/button";

export default function MainCard({title, href, children: content}) {
    return (
        <Card 
            title={title}
            subTitle={<hr className="m-2"></hr>}
            className={"hover:shadow-5 cursor-pointer flex flex-column h-full surface-100"}
            style={{ transition: 'transform 0.2s' }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.02)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            onClick={() => window.location.href = href}
            footer={
                <Button 
                    icon="pi pi-arrow-right" 
                    className={"p-button-rounded p-button-secondary p-button-text p-button-lg"}
                    tooltip={"Go to the " + title}
                />
            }
        >
            {content}
        </Card>
    )
}
// ============= EOF =============================================