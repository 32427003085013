import nmwdi_logo from "../img/nmwdi_logo11-23.png";
import { Button } from "primereact/button";

export default function Footer() {
  return (
    <section>
      <footer className={""}>
        <hr></hr>
        <div className="grid">
          <div className="col-12 md:col-3 p-4 md:p-6 flex align-items-center justify-content-center">
            <a href="https://newmexicowaterdata.org" target="_blank" rel="noopener noreferrer">
              <img src={nmwdi_logo} height="80px" alt="NMWDI Logo" />
            </a>
          </div>
          <div className="col-12 md:col-3 pt-1 pb-1 p-4 md:p-6 flex align-items-center justify-content-center flex-column">
            <h4>Support:</h4>
            <ul className="list-none p-0 m-0">
              <li key="nmwdi" className="mb-2">
                <Button label="Service Management Portal" className="p-button-link" onClick={() => window.open("https://nmbgmr.atlassian.net/servicedesk/customer/portal/1", "_blank")} />
              </li>
              {/* Hidden for now - uncomment once built out */}
              {/* <li key="nmwdi_docs" className="mb-2">
                <Button label="Weaver Documentation" className="p-button-link" onClick={() => window.open("/docs", "_blank")} />
              </li> */}
            </ul>
          </div>
          <div className="col-12 md:col-3 pt-1 pb-1 p-4 md:p-6 flex align-items-center justify-content-center flex-column">
            <h4>About:</h4>
            <ul className="list-none p-0 m-0">
              <li key="nmwdi" className="mb-2">
                <Button label="New Mexico Water Data Initiative" className="p-button-link" onClick={() => window.open("https://newmexicowaterdata.org", "_blank")} />
              </li>
              <li key="nmwdi_catalog" className="mb-2">
                <Button label="New Mexico Water Data Catalog" className="p-button-link" onClick={() => window.open("https://catalog.newmexicowaterdata.org", "_blank")} />
              </li>
            </ul>
          </div>
          <div className="col-12 md:col-3 pt-1 pb-1 p-4 md:p-6 flex align-items-center justify-content-center flex-column">
            <h4>Data provided by:</h4>
            <ul className="list-none p-0 m-0">
              <li key="nmbgmr" className="mb-2">
                <Button label="New Mexico Bureau of Geology Water API" className="p-button-link" onClick={() => window.open("https://waterdata.nmt.edu", "_blank")} />
              </li>
              <li key="usgs" className="mb-2">
                <Button label="USGS Water Services API" className="p-button-link" onClick={() => window.open("https://waterservices.usgs.gov/", "_blank")} />
              </li>
            </ul>
          </div>

          {/*<div className={"text-center p-3"}>*/}
          {/*  <p>*/}
          {/*    <a href="http://waterdata.nmt.edu">NM WaterData</a>*/}
          {/*  </p>*/}
          {/*</div>*/}

          {/*<div className={"text-center p-3"}>*/}
          {/*  <p>© 2023 Weaver</p>*/}
          {/*</div>*/}
        </div>
      </footer>
    </section>
  );
}
