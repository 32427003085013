// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import "mapbox-gl/dist/mapbox-gl.css";
import "./PVACDMap.css";

import { settings } from "../../../settings";
import { Layer, Map, Popup, Source, NavigationControl } from "react-map-gl";
import MapStyleControl from "./MapStyleControl";
import DataTableControl from "./DataTableControl";
import PVACDHelp from "./PVACDHelp";
import { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import MapTitleControl from "./MapTitleControl";
import { Sidebar } from "primereact/sidebar";
import { Card } from "primereact/card";

import { isTouchDevice } from "../../../util";

const MAP_STYLES = [
    { label: "Light", value: "mapbox://styles/mapbox/light-v10" },
    { label: "Dark", value: "mapbox://styles/mapbox/dark-v10" },
    { label: "Outdoors", value: "mapbox://styles/mapbox/outdoors-v12" },
    { label: "Satellite", value: "mapbox://styles/mapbox/satellite-streets-v12" },
    ];

export default function PVACDMap({
  mapRef,
  initialViewState,
  style,
  sources,
  sourceData,
  setSourceData,
  popupContent,
  setPopupContent,
  onMapClickCallback,
  selectedLocations,
  toggleDataTable,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedmapStyle, setSelectedMapStyle] = useState(() => {
    const theme = localStorage.getItem('theme') || 'light';
    return theme === 'dark' ? MAP_STYLES[1].value : MAP_STYLES[0].value;
  });

  //state managment for map info control
  const [showInfoSidebar, setShowInfoSidebar] = useState(false);   

  const toggleInfoSidebar = () => {
    setShowInfoSidebar(prev => !prev);
    };

  // Update the feature state for selected locations - to use with styling of points conditional on selection
  useEffect(() => {
    if (!mapRef || !mapRef.current || !selectedLocations) return;
  
    const map = mapRef.current;
  
    function updateFeatureStates() {
      sources.forEach((source) => {
        const sourceId = source.id;
  
        const mapSource = map.getSource(sourceId);
        if (!mapSource || !mapSource._data || !mapSource._data.features) return;
  
        mapSource._data.features.forEach((feature) => {
          map.setFeatureState(
            { source: sourceId, id: feature.id },
            { selected: false }
          );
        });
  
        selectedLocations.forEach((location) => {
          map.setFeatureState(
            { source: sourceId, id: location.id },
            { selected: true }
          );
        });
      });
    }
  
    if (!map.isStyleLoaded()) {
      map.once('styledata', updateFeatureStates);
    } else {
      updateFeatureStates();
    }
  }, [selectedLocations, mapRef, sources]);

  useEffect(() => {
    if (!setSourceData) return;
  
    for (const source of sources) {
      if (sourceData[source.id]) continue;
  
      setLoading(true);
      source.dataFetcher().then((data) => {
        if (!data) {
          setLoading(false);
          return;
        }
        data.features = data.features.map((feature, index) => {
          const id = feature.id || feature.properties.name || index;
          return {
            //add id to feature to make sure it can match table selection
            ...feature,
            id: id, 
            properties: {
              ...feature.properties,
              id: id,
            },
          };
        });
  
        data["properties"] = { data_source: source.id, url: source.url };
        setSourceData((prevData) => ({ ...prevData, [source.id]: data }));
        setLoading(false);
      });
    }
  }, [sources, sourceData, setSourceData]);

  const layers = sources.map((source) => {
    if (!sourceData[source.id]) return null;

    return (
      <Source
        key={source.id}
        id={source.id}
        type="geojson"
        data={sourceData[source.id]}
        promoteId="id"
      >
        <Layer
          id={source.id}
          type={source.layer_type || "circle"}
          paint={{
            "circle-radius": 5.75,
            "circle-color": [
              "case",
              ["boolean", ["feature-state", "selected"], false],
              "red", // for selected sites
              "rgba(255, 255, 255, 0.9)"  //for non-selected sites
            ],
            "circle-stroke-width": 1.75,
            "circle-stroke-color": "#000000"
          }}
          filter={["==", "$type", "Point"]}
        />
      </Source>
    );
  });

  const onMapClick = (e) => {
    if (!mapRef || !mapRef.current) return;
    const features = mapRef.current.queryRenderedFeatures(e.point);
    const sourceIds = sources.map((s) => s.id);
    const clickedFeatures = features.filter(
      (f) => f.layer && sourceIds.includes(f.layer.source)
    );

    if (clickedFeatures.length === 0) {
      setPopupContent(undefined);
      return;
    }

    const feature = clickedFeatures[0];
    if (onMapClickCallback) {
      onMapClickCallback(e, [feature]);
    } else {
      setPopupContent({
        coordinates: feature.geometry.coordinates,
        children: (
          <h2>
            <strong>{feature.properties.name}</strong>
          </h2>
        ),
      });
    }
  };

  const onMouseMove = (e) => {
    if (!mapRef || !mapRef.current) return;
    const features = mapRef.current.queryRenderedFeatures(e.point);
    const sourceIds = sources.map((s) => s.id);
    const hoveredFeatures = features.filter(
      (f) => f.layer && sourceIds.includes(f.layer.source)
    );

    if (hoveredFeatures.length > 0) {
        mapRef.current.getCanvas().style.cursor = "pointer";
    }
    else {
        mapRef.current.getCanvas().style.cursor = "grab";
    }

    if (hoveredFeatures.length === 0) {
      setPopupContent(undefined);
      return;
    }
  
    const feature = hoveredFeatures[0];
    setPopupContent({
      coordinates: feature.geometry.coordinates,
      children: (
        <h2>
          <strong style={{ color: "black "}}>{feature.properties.name}</strong>
        </h2>
      ),
    });
  };

  const sidebarWidth = isTouchDevice() ? "100vw" : "33vw";

  return (
    <div className="map-container">
      <Map
        ref={mapRef}
        initialViewState={initialViewState}
        style={style}
        mapStyle={selectedmapStyle}
        mapboxAccessToken={settings.mapbox.token}
        onClick={onMapClick}
        onMouseMove={onMouseMove}
        //onTouchEnd={onTouchEnd}
      >
        {layers}
        {popupContent && (
          <Popup
            latitude={popupContent.coordinates[1]}
            longitude={popupContent.coordinates[0]}
            closeButton={false}
            closeOnClick
            onClose={() => setPopupContent(undefined)}
          >
            {popupContent.children}
          </Popup>
        )}
        <NavigationControl position="top-right" />

        <div className="map-title-control-container">
            <MapTitleControl onClick={toggleInfoSidebar}/>
        </div>

        <div className="map-style-control-container">
        <MapStyleControl
            styles={MAP_STYLES}
            currentStyle={selectedmapStyle}
            onChange={(style) => setSelectedMapStyle(style)}
        />
        </div>
        <div className="data-table-control-container">
          <DataTableControl onClick={toggleDataTable} />
        </div>
      </Map>

      <Sidebar visible={showInfoSidebar} onHide={() => setShowInfoSidebar(false)} position="left" style={{ width: sidebarWidth }}>
        <Card 
          title="PVACD Dashboard"
          subTitle="Information & Help"
        >
          <PVACDHelp />
        </Card>
      </Sidebar>

      {loading && (
        <div className="absolute bottom-10">
          <ProgressSpinner strokeWidth="8" />
        </div>
      )}
    </div>
  );
}