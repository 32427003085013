import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';

const MapStyleControl = ({ styles, onChange, currentStyle }) => {
    return (
          <div className="map-style-control p-col-12 p-md-6 p-fluid">
            <Tag value="Map Style" severity="info" />
            <Dropdown
              value={currentStyle}
              options={styles}
              onChange={(e) => onChange(e.value)}
              optionLabel="label"
              optionValue="value"
              tooltip='Select Map Style'
              tooltipOptions={{ position: 'left' }}

            />
          </div>
      );
    };

export default MapStyleControl;