// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import "./NavBar.css";

import { Button } from "primereact/button";
import {
  useFiefAuth,
  useFiefIsAuthenticated,
  useFiefTokenInfo,
  useFiefUserinfo,
} from "@fief/fief/react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import nmwdi_logo from "../../img/nmwdi_logo11-23.png";
import { Menubar } from "primereact/menubar";
import { Avatar } from "primereact/avatar";
import { Checkbox } from "primereact/checkbox";
import { sha256 } from "js-sha256";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { jwtDecode } from "jwt-decode";
import {
  batch_manual_waterlevels_upload,
  chem_upload,
 // continuous,
  data_view,
  die,
  manual_water_level_entry,
  well_upload,
} from "./SecureMenuItems";
import {
  collabnet,
  maps,
  // documentation,
  home,
  tools,
  wells,
  // confirm,
  groundwater,
} from "./PublicItems";
import { InputSwitch } from "primereact/inputswitch";
import { isTouchDevice } from "../../util";

const validatePermissions = (scopes, ...requiredScopes) => {
  return requiredScopes.every((scope) => scopes.includes(scope));
};

function assembleSecureItems(scopes) {
  let secure_items = [];
  if (validatePermissions(scopes, "manualwaterlevel:write")) {
    secure_items.push(manual_water_level_entry);
  }
  if (validatePermissions(scopes, "chem:write")) {
    secure_items.push(chem_upload);
  }
  if (validatePermissions(scopes, "die:user")) {
    secure_items.push(die);
  }
  if (validatePermissions(scopes, "well:read", "location:read", "owner:read")) {
    secure_items.push(wells, data_view);
  }
  if (validatePermissions(scopes, "well:write")) {
    secure_items.push(well_upload);
  }
  if (validatePermissions(scopes, "manualwaterlevel:batch:write")) {
    secure_items.push(batch_manual_waterlevels_upload);
  }
  return secure_items;
}

function AppNavbar() {
  const fiefAuth = useFiefAuth();
  const isAuthenticated = useFiefIsAuthenticated();
  const userinfo = useFiefUserinfo();
  const tokenInfo = useFiefTokenInfo();
  const toast = useRef(null);
  const [scopes, setScopes] = useState(["manualwaterlevelentry:write"]);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const logout = useCallback(() => {
    fiefAuth.logout(`${window.location.protocol}//${window.location.host}`);
  }, []);

  const login = useCallback(() => {
    try {
      fiefAuth.redirectToLogin(
        `${window.location.protocol}//${window.location.host}/callback`,
      );
    } catch (error) {
      console.log("login error", error);

      confirmDialog({
        message: "There was an error logging in. Please try again.",
        header: "Login Error",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          console.log("accept");
        },
      });
      fiefAuth.storage.clearUserinfo();
      fiefAuth.storage.clearTokeninfo();
    }
  }, [fiefAuth]);

  let [loginlabel, onClick] =
    isAuthenticated && userinfo ? ["Logout", logout] : ["Login", login];

  const showLabel = !isTouchDevice();

  // return (<Button  onClick={()=> setHelpVisible(true)} severity="help" label={"Help"}/>)
  const brand = (
    <a href={"https://newmexicowaterdata.org"}>
      <img src={nmwdi_logo} height="62px" />
    </a>
  );

  useEffect(() => {
    if (!tokenInfo) {
      return;
    }
    const token = jwtDecode(tokenInfo?.access_token);
    if (token?.permissions) {
      console.log("token", token?.permissions);
      setScopes(token?.permissions);
    }
  }, [tokenInfo]);

  useEffect(() => {
    let themeLink = document.getElementById('theme-link');
    if (!themeLink) {
      
      themeLink = document.createElement('link');
      themeLink.id = 'theme-link';
      themeLink.rel = 'stylesheet';
      document.head.appendChild(themeLink);
    }
    themeLink.href = `/themes/bootstrap4-${theme}-blue/theme.css`;
    localStorage.setItem('theme', theme);
  }, [theme]);

  const gravatarUrl = (email) => {
    let hash = email.trim().toLowerCase();
    return `https://www.gravatar.com/avatar/${sha256(hash)}`;
  };

  let loginout_div = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
    <i className="pi pi-sun" style={{ marginRight: '0.5rem' }}></i>
    <InputSwitch
    checked={theme === 'dark'} 
    onChange={(e) => setTheme(e.value ? 'dark' : 'light')} 
    />
    <i className="pi pi-moon mr-2" style={{ marginLeft: '0.5rem' }}></i>
    <span style={{ marginLeft: '10px' }}>

      {isAuthenticated && userinfo ? (
        <>
        <Avatar
          label={userinfo?.email.substring(0, 1).toUpperCase()}
          image={gravatarUrl(userinfo?.email)}
          className="mr-2"
          shape="circle"
          size="normal"
        ></Avatar>
        <Button 
        label={showLabel ? loginlabel : ""} 
        onClick={onClick} 
        icon={"pi pi-fw pi-user"}
        outlined
        raised
        severity="secondary" 
      />
      </>
      ) : (
      <Button 
        label={showLabel ? loginlabel : ""} 
        onClick={onClick} 
        icon={"pi pi-fw pi-user"}
        raised
        severity="info" 
      />
      )}
      </span>
    </div>
  );

  // const ckan = {
  //   id: "ckan",
  //   label: "Data Catalog",
  //   icon: "pi pi-fw pi-cloud",
  //   command: () => {
  //     // ;
  //     {
  //       localStorage.getItem("hideDataCatalogDecision") === "true"
  //         ? (window.location.href = "https://catalog.newmexicowaterdata.org")
  //         : confirm();
  //     }
  //   },
  // };

  let items = [home];
  // if (isAuthenticated && validatePermissions(scopes, "pvacd:read")) {
  //   const pvacd = {
  //     id: "pvacd",
  //     label: "PVACD",
  //     icon: "pi pi-fw pi-cloud",
  //     command: () => {
  //       window.location.href = "/pvacd";
  //     },
  //   };
  //   const amaps = {
  //     id: "maps",
  //     label: "Dashboards",
  //     icon: "pi pi-fw pi-map",
  //     items: [groundwater, pvacd],
  //   };
  //   items.push(amaps);
  // } else {
  //   items.push(maps);
  // }
  items.push(maps, collabnet, tools);

  if (isAuthenticated) {
    const authenticated = {
      id: "secure",
      label: "Secure Area",
      icon: "pi pi-fw pi-exclamation-triangle",
      items: assembleSecureItems(scopes),
    };
    items.push(authenticated);
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Menubar
        model={items}
        start={brand}
        end={loginout_div}
        className={"custom-menubar"}
      />
    </>
  );
}

export default AppNavbar;
// ============= EOF =============================================
