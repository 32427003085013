// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Card } from "primereact/card";
import PVACDMap from "./PVACDMap"
//import MapComponent from "../../Map/MapComponent";
import { pvacd_gw_locations } from "../Groundwater/Sources";
import React, { useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { mToFt, retrieveItems } from "../../../util";
import Plot from "react-plotly.js";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import { isTouchDevice } from "../../../util";
import { downloadPVACDData } from "./PVACDDownload";

const sources = [pvacd_gw_locations];

export default function PVACDDashboard() {
  const [sourceData, setSourceData] = useState({});
  const [locations, setLocations] = useState([]);
  const [enhancedLocations, setEnhancedLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [plotData, setPlotData] = useState([]);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef();

  const [popupContent, setPopupContent] = useState(undefined);
  //const [stickyPopup, setStickyPopup] = useState(false);

  //Data table display logic
  const [showDataTable, setShowDataTable] = useState(false);
  const toggleDataTable = () => {
    setShowDataTable((prev) => !prev);
  };

  //dialog display logic
  const [hydrographSelectedSite, setHydrographSelectedSite] = useState(null);
  const [showHydrograph, setShowHydrograph] = useState(false);

  //active tabe display logic
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  //download loading state logic
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [layout, setLayout] = useState({
    autosize: true,
    plot_bgcolor: "",
    paper_bgcolor: "",
    margin: { t: 50, b: 100, l: 50, r: 50, pad: 4 },
    xaxis: { title: "Date" },
    yaxis: { title: "Depth to Water (ft bgs)", autorange: "reversed" },
  });

  useEffect(() => {
    console.log("sourceData", sourceData);
    if (!sourceData["weaver:gw:pvacd"]) return;
    console.log("sourceData", sourceData["weaver:gw:pvacd"].features);
    setLocations(sourceData["weaver:gw:pvacd"].features);
  }, [sourceData]);

  useEffect(() => {
    if (locations.length === 0) return;

    const fetchLatestObservations = async () => {
      const updatedLocations = await Promise.all(
        locations.map(async (location) => {
          const url = location.properties["selflink"] + "?$expand=Things/Datastreams";
          try {
            const response = await fetch(url);
            const data = await response.json();
            const datastreams = data.Things.map((t) => t.Datastreams).flat();

            const observationPromises = datastreams.map(async (ds) => {
              const obsUrl =
                ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc&$top=1";
              try {
                const obsResponse = await fetch(obsUrl);
                const obsData = await obsResponse.json();
                const observation = obsData.value[0];
                // console.log("observation", observation);
                return {
                  datastreamName: ds.name,
                  observation: observation,
                };
              } catch (error) {
                console.error("Error fetching observation", error);
                return null;
              }
            });

            const observations = await Promise.all(observationPromises);
            return {
              ...location,
              //id used for selection to map feature matching
              id: location.id,
              latestObservations: observations,
            };
          } catch (error) {
            console.error("Error fetching datastreams", error);
            return location;
          }
        })
      );

      setEnhancedLocations(updatedLocations);
    };

    fetchLatestObservations();
  }, [locations]);

  useEffect(() => {
    if (hydrographSelectedSite && hydrographSelectedSite.length > 0) {
      plot_hygrogaphs(hydrographSelectedSite);
      setShowHydrograph(true);
    } else {
      setShowHydrograph(false);
    }
  }, [hydrographSelectedSite]);

  // Mouse move for map popup
  // const onMouseMove = (e, features, selected_points) => {
  //   const point = selected_points[0];
  //   setPopupContent({
  //     coordinates: e.lngLat.toArray(),
  //     children: 
  //       <h2>
  //         <strong>
  //           {point.properties.name}
  //         </strong>
  //       </h2>,
  //   });
  // };

  const onMapClick = (event, features) => {
    if (features && features.length > 0) {
      const clickedFeature = features[0];

      const hydrographSelectedSite = enhancedLocations.find(
        (site) => site.properties.name === clickedFeature.properties.name
      );

      if (hydrographSelectedSite) {
        console.log("hydrographSelectedSite", hydrographSelectedSite);
        setHydrographSelectedSite([hydrographSelectedSite]);
        setShowHydrograph(true);

        setPopupContent({
          coordinates: clickedFeature.geometry.coordinates,
          children: (
            <h2>
              <strong style={{ color: "black "}}>{clickedFeature.properties.name}</strong>
            </h2>
          ),
        });
      }
    }
  };

  const plot_hygrogaphs = (items) => {
    let promises = items.map((item) => {
      let url = item.properties["selflink"] + "?$expand=Things/Datastreams";
      // console.log("uads", item, url);
      return fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let datastreams = data.Things.map((t) => t.Datastreams).flat();
          console.log("datastreams", datastreams);
          let dspromise = datastreams.map((ds) => {
            return retrieveItems(
              ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc",
              [],
            ).then((observations) => {
              return {
                mode: ds.name.includes("Manual") ? "markers" : "lines",
                x: observations.map((s) => s.phenomenonTime),
                y: observations.map((s) => s.result),
                name: `${item.properties.name} (${ds.name.includes("Manual") ? "Manual" : "Continuous"})`, 
              };
            });
          });
          return Promise.all(dspromise);
        });
    });

    setLoading(true);
    Promise.all(promises)
      .then((data) => {
        console.log("data", data.flat());
        setPlotData(data.flat());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownload = () => {
    setDownloadLoading(true);
    downloadPVACDData(selectedLocations, plotData).finally(() => {
    setDownloadLoading(false);
    });
  };

  const sidebarWidth = isTouchDevice() ? "100vw" : "50vw";

  return (
    <>
    <div 
        style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 110px)",
        margin: 0,
        padding: 0,
      }}>
      <div style={{ flexGrow: 1 }}>
        <PVACDMap
            mapRef={mapRef}
            initialViewState={{ longitude: -104.216, latitude: 33.20, zoom: 8 }}
            style={{ width: "100%", height: "calc(100vh - 110px)", margin: 0, padding: 0 }}
            sources={sources}
            sourceData={sourceData}
            setSourceData={setSourceData}
            popupContent={popupContent}
            setPopupContent={setPopupContent}
            onMapClickCallback={onMapClick}
            selectedLocations={selectedLocations}
            toggleDataTable={toggleDataTable}
          />
        </div>
    </div>
    <Sidebar
        visible={showDataTable}
        onHide={() => setShowDataTable(false)}
        position="right"
        style={{ width: sidebarWidth }}
      >
        <Card
          title="PVACD Summary Table"
          subTitle="Launch the hydrograph / site info, or select locations to download data"
        >
        <DataTable
          size="small"
          value={enhancedLocations}
          selection={selectedLocations}
          dataKey="id"
          onSelectionChange={(e) => {
            setSelectedLocations(e.value);

            if (e.value && e.value.length === 1) {
              const selectedLocation = e.value[0];
              const coordinates = [
                selectedLocation.geometry.coordinates[0],
                selectedLocation.geometry.coordinates[1],
              ];

              setPopupContent({
                coordinates: coordinates,
                children: (
                  <h2>
                    <strong style={{ color: "black "}}>{selectedLocation.properties.name}</strong>
                  </h2>
                ),
              });
            } else {
              setPopupContent(undefined);
            }
          }}
          stripedRows
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
            <Column sortable field={"properties.name"} header={"Name"}></Column>
            <Column 
              header="Actions"
              body={(item) => {
                return (
                  <div className="flex">
                  <Button
                    severity="warning"
                    style={{ marginRight: "0.2rem" }}
                    rounded
                    icon="pi pi-chart-line"
                    tooltip="View Hydrograph"
                    onClick={() => {
                      setHydrographSelectedSite([item]);
                      setActiveTabIndex(1);
                      toggleDataTable();
                    }}
                  />
                  <Button
                    severity="info"
                    rounded
                    outlined
                    icon="pi pi-arrow-up-right"
                    tooltip="More information"
                    onClick={() => {
                      setHydrographSelectedSite([item]);
                      setActiveTabIndex(0);
                      toggleDataTable();
                    }}
                  />
                  </div>
                );
              }}
            />
            <Column
              header="Latest Manual Water Level (ft bgs)"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const manualObs = item.latestObservations.find(
                  (obs) => obs && obs.datastreamName.includes("Manual")
                );
                return manualObs && manualObs.observation
                  ? manualObs.observation.result.toFixed(2)
                  : "N/A";
              }}
            />
            <Column
              header = "Latest Manual Measurement Date"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const manualObs = item.latestObservations.find(
                  (obs) => obs && obs.datastreamName.includes("Manual")
                );
                return manualObs && manualObs.observation
                  ? new Date(manualObs.observation.phenomenonTime).toLocaleDateString()
                  : "N/A";
              }}
            >
            </Column>
            <Column
              header="Latest Continuous Water Level (ft bgs)"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const continuousObs = item.latestObservations.find(
                  (obs) => obs && !obs.datastreamName.includes("Manual")
                );
                return continuousObs && continuousObs.observation
                  ? continuousObs.observation.result.toFixed(2)
                  : "N/A";
              }}
            />
            <Column
              header="Latest Continuous Measurement Date"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const continuousObs = item.latestObservations.find(
                  (obs) => obs && !obs.datastreamName.includes("Manual")
                );
                return continuousObs && continuousObs.observation
                  ? new Date(continuousObs.observation.phenomenonTime).toLocaleDateString()
                  : "N/A";
              }}
              >
              </Column>
        </DataTable>
        </Card>
        {(!selectedLocations || selectedLocations.length === 0) && (
          <>
           <Divider />
            <Button
              disabled={true}
              rounded
              label="Download Data"
              icon="pi pi-download"
              severity="warning"
            />
          </>
          )}
        {selectedLocations && selectedLocations.length >= 1 && (
          <>
          <Divider />
            <Button
              rounded
              label="Download Data"
              icon="pi pi-download"
              severity="warning"
              tooltip="Download CSV Data for Selected Location(s)"
              loading={downloadLoading}
              onClick={() => {
                handleDownload();
              }}
            />
          </>
        )}
      </Sidebar>

      <Dialog
        header={hydrographSelectedSite ? hydrographSelectedSite[0].properties.name: "No Site Selected"}
        visible={showHydrograph}
        style={{ width: '70vw' }}
        onHide={() => {
          setShowHydrograph(false);
          setHydrographSelectedSite(null);
          setActiveTabIndex(0);
        }}
        maximizable
        footer={
          <Button
            label='All PVACD Data'
            icon="pi pi-table"
            onClick={toggleDataTable}
            className="button p-button-raised p-button-info"
            tooltip="Show Data Table"
            tooltipOptions={{ position: 'left' }}
          />
        }
      >
        <TabView
          activeIndex={activeTabIndex}
        >
        <TabPanel header="Site Information">
            {hydrographSelectedSite && hydrographSelectedSite[0] ? (
              <Card
                title={hydrographSelectedSite[0].properties.name}
                subTitle={hydrographSelectedSite[0].properties.agency}
              >
                <Message
                  severity="info"
                  text="Check the 'Hydrograph' tab for a detailed water level visualization."
                  style={{ marginBottom: '20px' }}
                />
                <div className="p-field">
                  <strong>Well Depth:</strong>{" "}
                  {hydrographSelectedSite[0].properties.well_depth.value}{" "}
                  {hydrographSelectedSite[0].properties.well_depth.unit}
                </div>
                <div className="p-field">
                  <strong>Hole Depth:</strong>{" "}
                  {hydrographSelectedSite[0].properties.hole_depth.value}{" "}
                  {hydrographSelectedSite[0].properties.hole_depth.unit}
                </div>
                <Divider />
                <DataTable value={[hydrographSelectedSite[0]]} className="p-datatable-gridlines">
                <Column
                  header="Latest Manual Water Level (ft bgs)"
                  body={() =>
                    hydrographSelectedSite[0].latestObservations.find((obs) =>
                      obs.datastreamName.includes("Manual")
                    )?.observation.result || "N/A"
                  }
                />
                <Column
                  header="Manual Measurement Date"
                  body={() => {
                    const manualObs = hydrographSelectedSite[0].latestObservations.find((obs) =>
                      obs.datastreamName.includes("Manual")
                    );
                    return manualObs
                      ? new Date(manualObs.observation.resultTime).toLocaleDateString()
                      : "N/A";
                  }}
                />
                <Column
                  header="Latest Continuous Water Level (ft bgs)"
                  body={() =>
                    hydrographSelectedSite[0].latestObservations.find((obs) =>
                      !obs.datastreamName.includes("Manual")
                    )?.observation.result.toFixed(2) || "N/A"
                  }
                />
                <Column
                  header="Continuous Measurement Date"
                  body={() => {
                    const continuousObs = hydrographSelectedSite[0].latestObservations.find((obs) =>
                      !obs.datastreamName.includes("Manual")
                    );
                    return continuousObs
                      ? new Date(continuousObs.observation.resultTime).toLocaleDateString()
                      : "N/A";
                  }}
                />
              </DataTable>
              </Card>
            ) : (
              <p>No site information available</p>
            )}
          </TabPanel>
          <TabPanel header="Hydrograph">
            <div
              className="grid justify-content-center relative"
              style={{
                width: '100%',
                height: '520px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <div className="absolute bottom-50 right-50 z-1">
                {loading && <ProgressSpinner strokeWidth={5} />}
              </div>
              <div style={{ width: '100%', height: '100%' }}>
                <Plot
                  data={plotData}
                  layout={{
                    ...layout,
                    autosize: true,
                    legend: {
                      orientation: 'h',
                      yanchor: 'top',
                      y: -0.2,
                      xanchor: 'center',
                      x: 0.5,
                    },
                  }}
                  config={{ responsive: true }}
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Dialog>
    </>
  );
}
// ============= EOF =============================================
